import React from "react"

import s from "./Footer.module.scss"

import TLogo from "../../../images/t-logo.png"
import DrinkAware from "../../../images/drinkaware.png"
import Pint from "../../../images/pint.png"

export const Footer = ({ menu }) => {
  return (
    <div className={`${s.Footer} ${menu && s.Footer___menu}`}>
      {/* <img src={Pint} className={s.Footer__Pint} /> */}
      <div className={s.Footer__Row}>
        <div>
          <img src={TLogo} className={s.Footer__Logo} alt="" />
        </div>
        <div className={s.Footer__BIO}>
          <span>BRING IT ON</span>
        </div>
        <div className={s.Footer__DrinkAware}>
          <a href="https://www.drinkaware.co.uk/">
            <img src={DrinkAware} alt="" />
          </a>
        </div>

        <div className={s.Footer__Links}>
          <a target="_blank" href="https://www.facebook.com/TennentsLager">
            Facebook
          </a>
          <a target="_blank" href="http://twitter.com/TennentsLager">
            Twitter
          </a>
          <a target="_blank" href="http://instagram.com/TennentsLager">
            Instagram
          </a>
          <a
            target="_blank"
            href="https://www.tripadvisor.co.uk/Attraction_Review-g186534-d3708256-Reviews-Tennents_Wellpark_Brewery-Glasgow_Scotland.html"
          >
            Trip Advisor
          </a>
        </div>
      </div>
      <div className={s.Footer__Row}>
        <div className={s.Footer__Sub}>
          <span>
            Please do not share with anyone under the legal purchase age for
            alcohol. Also, if you aren’t pintless, please drink responsibly
          </span>
        </div>
      </div>
      <div className={s.Footer__Row___sml}>
        <div className={s.Footer__Terms}>
          <a href="https://tennents.co.uk/privacy-policy/">Privacy Policy</a>
          <a href="https://tennents.co.uk/terms-and-conditions/">
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  )
}
