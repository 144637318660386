import React from "react"
import s from "./Button.module.scss"

export const Button = ({ children, className, secondary, onClick }) => {
  return (
    <div
      className={`${s.Wrapper} ${className && className} ${
        secondary && s.Wrapper___secondary
      }`}
      onClick={onClick && onClick}
    >
      {children}
    </div>
  )
}
