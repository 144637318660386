import React, { useRef, useState } from "react"
import { Link } from "gatsby"

import s from "./Cta.module.scss"

export const Cta = ({ className, title, link, onClick, external, target }) => {
  return (
    <div
      className={`${s.Cta} ${className && className}`}
      onClick={onClick && onClick}
    >
      {!external && link && (
        <Link className={s.Cta__link} to={link}>
          <span className={s.Cta__link__title}>{title}</span>
        </Link>
      )}

      {external && link && (
        <a className={s.Cta__link} href={link} target={target || "_self"}>
          <span className={s.Cta__link__title}>{title}</span>
        </a>
      )}

      {!link && <span className={s.Cta__link__title}>{title}</span>}
    </div>
  )
}
