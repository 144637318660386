import { Link } from "gatsby"
import PropTypes from "prop-types"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { useState, useEffect } from "react"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

import s from "./Header.module.scss"

import { Footer } from "../footer/Footer"

import Logo1x from "../../../images/Logo/logo.png"
import Logo2x from "../../../images/Logo/logo@2x.png"
import Logo1xWhite from "../../../images/Logo-White/Logo-White.png"
import Logo2xWhite from "../../../images/Logo-White/Logo-White@2x.png"
import T from "../../../images/T.png"
import Back from "../../../images/back.inline.svg"

export const Header = ({ showCta, dash, inGame, hideMenu, showBack }) => {
  const [menuActive, setMenuActive] = useState(false)

  useEffect(() => {
    if (menuActive) {
      window.scrollTo(0, 0)
      // let targetElement = document.getElementsByTagName("body")[0]
      setTimeout(() => disableBodyScroll(window), 250)
    } else {
      clearAllBodyScrollLocks()
    }
  }, [menuActive])

  return (
    <header
      className={`${s.Header} ${dash && s.Header___dash} ${
        inGame && s.Header___inGame
      } ${hideMenu && !showBack && s.Header___noBorder}`}
    >
      <div
        className={`${s.Header__inner} ${
          menuActive && s.Header__inner___active
        }`}
      >
        <div>
          <Link to="/">
            {inGame ? (
              <img
                className={s.Header__logo}
                srcset={`${Logo2xWhite} 2x, ${Logo1xWhite} 1x`}
                src={Logo1xWhite}
              />
            ) : (
              <img
                className={s.Header__logo}
                srcset={`${Logo2x} 2x, ${Logo1x} 1x`}
                src={Logo1x}
              />
            )}
          </Link>
        </div>

        <div
          className={`${s.Header__CTAWrapper} ${
            menuActive && s.Header__CTAWrapper___active
          }`}
        >
          {showCta && (
            <Link to="/start">
              <div
                className={`${s.Header__CTA} ${
                  menuActive && s.Header__CTA___active
                }`}
              >
                <span className={s.Header__CTA___desktop}>
                  Create Your Quiz
                </span>
                <span className={s.Header__CTA___mobile}>Start</span>
              </div>
            </Link>
          )}
          {dash ||
            (showBack && (
              <div
                className={s.Back}
                onClick={() => {
                  window.history.back()
                }}
              >
                <Back />
              </div>
            ))}
          {!hideMenu && (
            <div
              className={`${s.Burger} ${menuActive && s.Burger___active}`}
              onClick={() => {
                setMenuActive(!menuActive)
              }}
            >
              <div className={s.Burger__inner}>
                <span />
                <span />
                <span />
              </div>
            </div>
          )}
        </div>
      </div>
      <img src={T} className={s.T} />

      <div className={`${s.Menu} ${menuActive && s.Menu___active}`}>
        <div
          className={`${s.Menu__background} ${
            menuActive && s.Menu__background___active
          }`}
        />
        <div
          className={`${s.Menu__inner} ${menuActive && s.Menu__inner___active}`}
        >
          <div className={`${s.Menu__inner__links}`}>
            <Link
              to="/"
              onClick={() => {
                setMenuActive(false)
              }}
              activeClassName={s.Menu__link___active}
            >
              Home
            </Link>
            <Link
              to="/#wit"
              onClick={() => {
                setMenuActive(false)
              }}
            >
              What Is This?
            </Link>
            <Link
              to="/start"
              onClick={() => {
                setMenuActive(false)
              }}
            >
              Start a Quiz
            </Link>
            <a
              href="https://www.drygate.com/browse/c-New-In-10/b-Tennents-3"
              target="_blank"
            >
              Tennent’s Delivered
            </a>
          </div>
          <Footer menu={true} />
        </div>
      </div>
    </header>
  )
}
