import React, { useState } from "react"

import s from "./AgeGate.module.scss"

import { Button } from "../../common/button/Button"
import { Cta } from "../../common/cta/Cta"
import T from "../../../images/T.png"

export const AgeGate = ({ close }) => {
  const [ageError, setAgeError] = useState(false)
  const _setCookie = () => {
    if (typeof window === "object") {
      window.localStorage.setItem("18", true)
    }
    close()
  }

  return (
    <div className={s.Wrapper}>
      <img src={T} className={s.T} />
      <div className={s.Inner}>
        <p>Before we let you in...</p>
        <h3>Are you over 18?</h3>
        <div className={s.Buttons}>
          <Button
            className={s.Buttons__Button}
            onClick={() => {
              _setCookie()
            }}
          >
            <Cta title={"Yes"} />
          </Button>
          <Button
            className={s.Buttons__Button}
            onClick={() => setAgeError(true)}
          >
            <Cta title={"No"} link="" />
          </Button>
        </div>
        {ageError && (
          <p style={{ marginTop: 16 }}>
            Sorry, you have to be 18 or over to continue.
          </p>
        )}
      </div>
    </div>
  )
}
